body {
    overflow-x: hidden;
}

.fullscreen-background {
    background-image: url("../../images/ledr-background.jpg");
    background-color: #111;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    position: relative;
}

.fullscreen-background::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
}

.fullscreen-background > * {
    @apply relative z-10;
}

.logo {
    @apply mx-auto;
}

#login-logo .logo-signup {
    margin-bottom: 40px;
}

.content-wrapper {
    @apply w-full mx-auto rounded bg-white font-medium p-6 text-black lg:w-1/2 xl:w-1/3 lg:px-12 lg:py-10
        dark:bg-gray-900 dark:text-white;
}

.content-wrapper .subtitle {
    max-width: 240px;
    @apply font-bold mx-auto mb-10 text-center;
}

#login-password {
    @apply pr-12;
}

.content-wrapper .icon-eye,
.content-wrapper .icon-eye-slash {
    color: #bebebe;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    @apply absolute cursor-pointer z-10;
}

.content-wrapper .icon-eye:hover,
.content-wrapper .icon-eye-slash:hover {
    color: #ff364e;
}

.content-wrapper .haruki label {
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    @apply absolute m-0 ml-4 z-0 transition-all font-medium text-gray-700;
}

.content-wrapper .haruki input:focus + label,
.content-wrapper .haruki.active label {
    top: -50%;
    transform: none;
    @apply text-black ml-0 dark:text-white;
}

.content-wrapper .remember {
    font-size: 12px;
    @apply text-gray-700 dark:text-white;
}

.content-wrapper .remember label {
    font-size: 12px;
    position: relative;
    cursor: pointer;
    margin-left: 5px;

    @apply text-gray-700 dark:text-white;
}

.content-wrapper .remember input {
    visibility: hidden;
}

.content-wrapper .remember label::before {
    position: absolute;
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid #ccc;
    top: 2px;
    left: -20px;
}

.content-wrapper .remember input:checked + label::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ff364e;
    left: -18px;
    top: 4px;
    margin: 0 auto;
}

.no-access-link {
    @apply block text-white transition-all;
}

.no-access-link:hover {
    transform: scale(1.1);
    @apply text-white;
}

/* TOGGLE STYLING */
.toggle-test {
    box-sizing: border-box;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    @apply mb-4;
}

.toggle-test input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
}

.toggle-test input + label {
    margin: 0;
    cursor: pointer;
    padding: 1.2rem 1rem;
    box-sizing: border-box;
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgb(255 255 255 / 0%);
    transition:
        border-color 0.15s ease-out,
        color 0.25s ease-out,
        background-color 0.15s ease-out,
        box-shadow 0.15s ease-out;

    /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* ----- */

    @apply bg-white border dark:bg-gray-900 dark:border-gray-700;
}

.toggle-test input + label:first-of-type {
    border-radius: 1px 0 0 1px;
    border-right: none;
}

.toggle-test input + label:last-of-type {
    border-radius: 0 1px 1px 0;
    border-left: none;
}

.toggle-test input:hover + label {
    @apply border-gray-200 dark:border-gray-800;
}

.toggle-test input:checked + label {
    @apply bg-black border-black text-white z-10 shadow-xl dark:bg-white dark:border-white dark:text-black;
}

p.consent {
    font-size: 11px;
    font-weight: 400;
    color: #525252;
}
